import Collection from '@/helpers/Collection';
import moment from 'moment';
import BookDefaultImage from '@/assets/img/default-book.png';
import { i18n } from '../i18n';
import { EXANDRIA_TYPE } from './constants';
import Author from './Author';
import Review from './Review';

class ExandriaBook {
  constructor(data = {}) {
    Object.assign(this, data);

    this.categories = data.categories instanceof Array ? data.categories : [];

    this.type = EXANDRIA_TYPE;

    this.resolveAuthors();
    this.resolveTags();
    this.resolveTimes();
    this.resolveGenres();
    this.resolveDecades();
    this.resolveCenturies();
    this.resolveReviews();
    this.resolveCharacterNames();
    this.resolveRealCharacters();
    this.resolveTrueEvents();
    this.resolveScreenAdaptations();
    this.resolveTime();
    this.resolvePlaces();
    this.getStrongPoints();
  }

  getId() {
    return this.id;
  }

  getTitle() {
    return this.title;
  }

  getType() {
    return this.type;
  }

  getImage() {
    return this.image || BookDefaultImage;
  }

  getDescription() {
    return (this.comment || '').replace(/<[^>]+>/g, '');
  }

  resolveAuthors() {
    this.authors = (this.authors || this.Authors || []).map(
      (author) => new Author(author)
    );
  }

  hasAuthors() {
    return this.authors.length > 0;
  }

  getAuthors() {
    return new Collection(this.authors);
  }

  hasAuthorNationality() {
    return this.latestReview().hasAuthorNationality();
  }

  getAuthorNationality() {
    return this.latestReview().authorsNationality;
  }

  getISBN() {
    return this.isbn;
  }

  getPages() {
    const { pages } = this;
    return pages || '';
  }

  getPublishedDate() {
    const { publish_date: publishedDate } = this;
    return publishedDate ? moment(publishedDate).format('DD/MM/YYYY') : '';
  }

  getAvgRating() {
    const { averageRate } = this;
    const ratings = averageRate
      ? Object.values(averageRate).map((value) => Number(value))
      : [];
    return ratings.length
      ? (ratings.reduce((f, n) => f + n, 0) / ratings.length).toFixed(2)
      : 0;
  }

  resolveTimes() {
    this.Times = (this.Times || []).map((tag) => tag.name).toString();
  }

  resolveDecades() {
    this.Decades = (this.Decades || []).map(({ name }) => name);
  }

  resolveCenturies() {
    this.Centuries = (this.Centuries || []).map(({ name }) => name);
  }

  resolveGenres() {
    this.Genres = (this.Genres || []).map((genre) => genre.name);
  }

  getDecades() {
    return this.Decades;
  }

  getCenturies() {
    return this.Centuries;
  }

  resolveCharacterNames() {
    this.CharacterNames = (this.CharacterNames || [])
      .map((character) => character.name)
      .filter((name) => name);
  }

  hasCharacterNames() {
    return this.CharacterNames.length > 0;
  }

  getCharacterNames() {
    return new Collection(this.CharacterNames);
  }

  resolveRealCharacters() {
    this.RealCharacters = (this.RealCharacters || [])
      .map((character) => character.name)
      .filter((name) => name);
  }

  hasRealCharacters() {
    return this.RealCharacters.length > 0;
  }

  getRealCharacters() {
    return new Collection(this.RealCharacters);
  }

  resolveTrueEvents() {
    this.TrueEvents = (this.TrueEvents || [])
      .map((event) => event.name)
      .filter((name) => name);
  }

  hasTrueEvents() {
    return this.TrueEvents.length > 0;
  }

  getTrueEvents() {
    return new Collection(this.TrueEvents);
  }

  resolveScreenAdaptations() {
    this.screenAdaptations = this.ScreenAdaptations || [];
  }

  hasScreenAdaptations() {
    return this.screenAdaptations.length > 0;
  }

  getScreenAdaptations($t) {
    return new Collection(
      this.screenAdaptations.map(
        ({ name, category }) => `${$t ? $t(category) : category}: ${name}`
      )
    );
  }

  resolveTags() {
    this.Tags = (this.Tags || []).map((tag) => tag.name).filter((name) => name);
  }

  hasTags() {
    return this.Tags.length > 0;
  }

  getTags() {
    return new Collection(this.Tags);
  }

  resolveTime() {
    this.time = Array.from(
      new Set(
        [
          this.timeFrom &&
            (this.timeFrom < 0
              ? `${+this.timeFrom} ${i18n.t('BC')}`
              : `${this.timeFrom} ${i18n.t('AD')}`),
          this.timeTo &&
            (this.timeTo < 0
              ? `${+this.timeTo} ${i18n.t('BC')}`
              : `${this.timeTo} ${i18n.t('AD')}`),
        ].filter((c) => c)
      )
    ).join(' - ');
  }

  hasTime() {
    return !!this.time;
  }

  getTime() {
    return this.time;
  }

  hasPlace() {
    return !!this.place;
  }

  getPlace() {
    return this.place || '';
  }

  hasGenres() {
    return this.Genres.length > 0;
  }

  getGenres() {
    return new Collection(this.Genres);
  }

  getOriginality() {
    const { averageRate } = this;
    return averageRate ? Number(averageRate.originality) : '';
  }

  getPlot() {
    const { averageRate } = this;
    return averageRate ? Number(averageRate.plot) : '';
  }

  getWritingStyle() {
    const { averageRate } = this;
    return averageRate ? Number(averageRate.writingStyle) : '';
  }

  getEmotion() {
    const { averageRate } = this;
    return averageRate ? Number(averageRate.emotion) : '';
  }

  getAverageRating() {
    return this.averageRating || 0;
  }

  getStrongPoints() {
    return this.strongPoints || 0;
  }

  resolveReviews() {
    this.reviews = (this.reviews || this.Reviews || []).map(
      (review) => new Review(review)
    );
  }

  hasReviews() {
    return this.reviews.length > 0;
  }

  getReviews() {
    return this.reviews;
  }

  latestReview() {
    return this.reviews[this.reviews.length - 1];
  }

  resolvePlaces() {
    this.places = this.Places || [];
  }

  hasPlaces() {
    return this.places.length > 0;
  }

  getPlaceNames(languageCode = 'en') {
    return new Collection(
      this.places.map(({ nameEn, nameIt }) =>
        languageCode === 'en' ? nameEn || nameIt : nameIt || nameEn
      )
    );
  }

  isExandria() {
    return this.type === EXANDRIA_TYPE;
  }
}

export default ExandriaBook;
