import Collection from '@/helpers/Collection';
import moment from 'moment';
import BookDefaultImage from '@/assets/img/default-book.png';
import Author from './Author';
import { EXANDRIA_TYPE, GOOGLE_TYPE } from './constants';

class GoogleBook {
  constructor(data = {}) {
    Object.assign(this, data);

    this.type = GOOGLE_TYPE;

    this.categories = data.categories instanceof Array ? data.categories : [];

    this.image = { ...data.imageLinks };

    this.resolveAuthors();
    this.resolveISBN();
    this.resolveCharacterNames();
    this.resolveRealCharacters();
    this.resolveTrueEvents();
    this.resolveScreenAdaptations();
    this.resolveTags();
    this.resolveTime();
    this.resolveReviews();
    this.resolvePlaces();
  }

  getId() {
    return this.id;
  }

  getTitle() {
    return this.title;
  }

  getType() {
    return this.type;
  }

  getImage() {
    if (this.thumbnail) {
      return this.thumbnail;
    }

    return this.image.thumbnail || BookDefaultImage;
  }

  hasGenres() {
    return this.categories.length > 0;
  }

  getGenres() {
    return new Collection(this.categories);
  }

  resolveAuthors() {
    const authors = this.authors instanceof Array ? this.authors : [];

    this.authors = authors.map((name) => new Author({ name }));
  }

  hasAuthors() {
    return this.authors.length > 0;
  }

  getAuthors() {
    return new Collection(this.authors);
  }

  // eslint-disable-next-line class-methods-use-this
  hasAuthorNationality() {
    return false;
    // return this.getAuthorNationality().count();
  }

  // eslint-disable-next-line class-methods-use-this
  getAuthorNationality() {
    return '';
    // const nationalities = this.authors
    //   .map((author) => author.nationality)
    //   .flat()
    //   .filter((nationality) => nationality);
    // return new Collection(nationalities);
  }

  getDescription() {
    return (this.description || '').replace(/<[^>]+>/g, '');
  }

  resolveISBN() {
    this.ISBN =
      this.industryIdentifiers instanceof Array
        ? (
            this.industryIdentifiers.find(({ type }) => type === 'ISBN_10') ||
            {}
          ).identifier
        : '';
  }

  getISBN() {
    return this.ISBN;
  }

  getPages() {
    return this.pageCount;
  }

  getPublishedDate() {
    return moment(this.publishedDate).format('DD/MM/YYYY');
  }

  getAvgRating() {
    return this.averageRating || 0;
  }

  hasPlace() {
    return !!this.place;
  }

  getPlace() {
    return this.place;
  }

  resolveTags() {
    this.tags = [];
  }

  hasTags() {
    return this.tags.length > 0;
  }

  getTags() {
    return new Collection(this.tags);
  }

  getOriginality() {
    return this.originality;
  }

  getPlot() {
    return this.plot;
  }

  getWritingStyle() {
    return this.writingStyle;
  }

  getEmotion() {
    return this.emotion;
  }

  getAverageRating() {
    return this.averageRating || 0;
  }

  resolveCharacterNames() {
    this.characterNames = [];
  }

  hasCharacterNames() {
    return this.characterNames.length > 0;
  }

  getCharacterNames() {
    return new Collection(this.characterNames);
  }

  resolveRealCharacters() {
    this.realCharacters = [];
  }

  hasRealCharacters() {
    return this.realCharacters.length > 0;
  }

  getRealCharacters() {
    return new Collection(this.realCharacters);
  }

  resolveTrueEvents() {
    this.TrueEvents = [];
  }

  hasTrueEvents() {
    return this.TrueEvents.length > 0;
  }

  getTrueEvents() {
    return new Collection(this.TrueEvents);
  }

  resolveScreenAdaptations() {
    this.screenAdaptations = [];
  }

  hasScreenAdaptations() {
    return this.screenAdaptations.length > 0;
  }

  getScreenAdaptations() {
    return new Collection(this.screenAdaptations);
  }

  resolveTime() {
    this.time = [];
  }

  hasTime() {
    return this.time.length > 0;
  }

  getTime() {
    return new Collection(this.time);
  }

  resolveReviews() {
    this.reviews = [];
  }

  hasReviews() {
    return this.reviews.length > 0;
  }

  getReviews() {
    return this.reviews;
  }

  resolvePlaces() {
    this.places = this.Places || this.places || [];
  }

  hasPlaces() {
    return this.places.length > 0;
  }

  getPlaceNames(languageCode = 'en') {
    return new Collection(
      this.places.map(({ nameEn, nameIt }) =>
        languageCode === 'en' ? nameEn || nameIt : nameIt || nameEn
      )
    );
  }

  isExandria() {
    return this.type === EXANDRIA_TYPE;
  }
}

export default GoogleBook;
