import moment from 'moment';
import Collection from '@/helpers/Collection';
import Comment from './Comment';
import User from './User';

class Review {
  constructor(data = {}) {
    Object.assign(this, data);

    this.user = data.User ? new User(data.User) : new User({});
    this.resolveComments();
    this.resolveTags();
  }

  rating() {
    return Number(this.sumRating);
  }

  createdDate() {
    return this.createdAt && moment(this.createdAt);
  }

  hasAuthorNationality() {
    return (
      this.authorsNationality !== '' &&
      this.authorsNationality !== null &&
      this.authorsNationality !== undefined
    );
  }

  resolveComments() {
    const comments = this.Comments instanceof Array ? this.Comments : [];

    this.comments = comments.map((comment) => new Comment(comment));
  }

  resolveTags() {
    const tags = this.Tags instanceof Array ? this.Tags : [];

    this.tags = tags.map((tag) => tag.name).filter((name) => name);
  }

  hasTags() {
    return this.tags.length > 0;
  }

  getTags() {
    return new Collection(this.tags);
  }

  hasPlaces() {
    return this.Places.length > 0;
  }

  getPlaceNames(languageCode = 'en') {
    return new Collection(
      this.Places.map(({ nameEn, nameIt }) =>
        languageCode === 'en' ? nameEn || nameIt : nameIt || nameEn
      )
    );
  }
}

export default Review;
