class Collection {
  constructor(items) {
    if (!(items instanceof Array)) {
      throw new Error('Unsupported collection type');
    }

    this.items = items;
  }

  first() {
    return this.items.length > 0 ? this.items[0] : null;
  }

  count() {
    return this.items.length;
  }

  pluck(column) {
    return this.items.map((item) => item[column]);
  }

  toString() {
    return this.items.map((item) => (item ? item.toString() : '')).join(', ');
  }

  toArray() {
    return this.items;
  }
}

export default Collection;
