class Author {
  constructor(data = {}) {
    Object.assign(this, data);

    this.resolveNationality(data);
  }

  resolveNationality(data) {
    this.nationality =
      data.nationatily instanceof Array ? data.nationatily : [];
  }

  toString() {
    return this.name;
  }
}

export default Author;
