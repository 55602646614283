import User from './User';

class Comment {
  constructor(data = {}) {
    Object.assign(this, data);

    this.user = data.User ? new User(data.User) : new User({});
  }

  get text() {
    return this.comment;
  }
}

export default Comment;
